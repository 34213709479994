import { LoaderFunctionArgs, json } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import { db } from '@admin/drizzle/pool'
import { getStats } from '@admin/business/stats.server'
import { Resource } from 'sst'
import { pipe, R } from '@mobily/ts-belt'

export async function loader({ params, request }: LoaderFunctionArgs) {
  // console.dir({ resource: Resource }, { depth: null })
  // console.log('trying to access database')
  // const blarg = await db.query.users.findMany()

  const stats = await getStats()
  // const blarg = R.fromPredicate(42, () => true, new Error())
  let commitHash = 'unknown'
  if ('CommitHash' in Resource) {
    commitHash = Resource.CommitHash.value
  }
  // return json({
  //   stats,
  //   blarg,
  //   host: new URL(request.url).host,
  //   hostname: new URL(request.url).hostname,
  //   commitHash,
  // })
  return json({
    color: 'blue',
    stats,
    host: new URL(request.url).host,
    hostname: new URL(request.url).hostname,
    commitHash,
  })
}

export default function Index() {
  const { items, looks, users, designers } =
    useLoaderData<typeof loader>().stats
  const { host, hostname, commitHash } = useLoaderData<typeof loader>()
  return (
    <div className="flex w-full flex-col gap-8">
      <h1 className="font-serif text-h2">Dashboard</h1>
      <h3 className="font-sans text-h3">Items: {items?.count}</h3>
      <h3 className="font-sans text-h3">Looks: {looks?.count}</h3>
      <h3 className="font-sans text-h3">Designers: {designers?.count}</h3>
      <h3 className="font-sans text-h3">Users: {users?.count}</h3>
      <h3 className="font-sans text-h3">Host: {host}</h3>
      <h3 className="font-sans text-h3">Hostname: {hostname}</h3>
      <h3 className="font-sans text-h3">Commit hash: {commitHash ?? 'n/a'}</h3>
    </div>
  )
  // const data = useLoaderData<typeof loader>()
  // console.log(data)

  // return <h1>Hello world.</h1>
}
